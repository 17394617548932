<template>
  <div>
    <jzsy-header :show-home="false" title="停用账号管理"></jzsy-header>
    <div class="wrapper">
      <md-scroll-view ref="scrollView" class="scroll-view" :scrolling-x="false" immediate-check-end-reaching auto-reflow
            @end-reached="$_onEndReached">
        <md-field>
          <md-cell-item addon="启用" arrow v-for="item in datas" :key="item.staffId" @click="reinstate(item)">
            <div class="staff-info">
              <div class="staff-name">{{item.name}}</div>
              <div class="detail">手机号：{{item.mobile}}</div>
              <div class="detail">推荐码：{{item.referralCode}}</div>
            </div>
          </md-cell-item>
        </md-field>
        <md-scroll-view-more slot="more" :is-finished="isFinished" />
      </md-scroll-view>
    </div>
  </div>
</template>

<script>
import { ScrollView, ScrollViewRefresh, ScrollViewMore, CellItem } from 'mand-mobile'
import AdminApi from '@/api/admin'
import { Toast } from 'mand-mobile'

export default {
  components: {[ScrollView.name]: ScrollView,
      [ScrollViewMore.name]: ScrollViewMore,
      [ScrollViewRefresh.name]: ScrollViewRefresh,
      [CellItem.name]: CellItem
  },
  data() {
    return {
      datas: [],
      isFinished: false,
    }
  },
  methods: {
    $_onEndReached() {
      if (this.isFinished) {
        return
      }
      this.loadData()
    },
    reinstate(staff) {
      this.$dialog.confirm({
        content: `要启用账号【${staff.name}】吗？`,
        title: '确定',
        confirmWarning: true,
        onConfirm: () => {
          this.doReinstate(staff.staffId)
        }

      })
    },
    doReinstate(staffId) {
      AdminApi.reinstateStaff(staffId).then(() => {
        this.datas = this.datas.filter(i => i.staffId != staffId)
        Toast.succeed('操作成功')
      })
    },
    loadData() {
      let lastId = this.datas && this.datas.length ? this.datas[this.datas.length - 1].staffId : 0
      AdminApi.listQuitedStaff(lastId).then((res) => {
        if (!res.data.length) {
					this.isFinished = true
        } else {
					this.datas = this.datas.concat(res.data)
				}
        this.$refs.scrollView.finishLoadMore()
      })
    }
  }
}
</script>

<style scoped>
  .scroll-view {
    height: calc(100vh - 100px);
  }
  .staff-info {
    line-height: 36px;
  }
  .staff-name {
    font-size: 36px;
    margin-bottom: 20px;
  }
</style>